/* Reset some default styles */
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

.App-speech-button {
  width: 34px;
  height: 37px;
}
.suggestionButtons {
  display: flex;
  flex-direction: column;
}
.flexsuggesstionsx {
  height: 100% !important;
  justify-content: space-evenly;
}
.flexallmob {
  display: none;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

/* Body and html need to fill the full height */
html,
body {
  height: 100%;
  font-family: "Helvetica Neue", sans-serif;
}

/* Wrapper for layout */
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Header and Footer styles */
.header,
.footer {
  background-color: #333;
  color: white;
  padding: 15px;
  text-align: center;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

/* Content styles */
.content {
  flex: 1;
  padding: 15px;
  overflow-y: auto; /* Makes the content scrollable */
  background-color: #f4f4f4;
  min-height: 0; /* Ensures it takes the remaining space */
}

/* For demonstration purposes */
.content p {
  margin-bottom: 20px;
}

/* * {
  margin: 0;
  padding: 0;
} */

#root {
  height: 100%;
}
.flexheader {
  height: 10%;
}
/* @media (min-width: 300px) and (max-width: 400px) {
  .container1 {
    height: 70% !important;
  }
}
@media (min-width: 401px) and (max-width: 500px) {
  .container1 {
    height: 80% !important;
  }
} */
.container1 {
  height: 72%;
}
.container0 {
  height: 63%;
}

@media (min-width: 1550px) {
  .container1 {
    height: 76% !important;
  }
  .container0 {
    height: 69% !important;
  }
}

/* @media (min-width: 601px) and (max-width: 1550px) {
  .container0 {
    height: 80% !important;
  }
}

@media (max-width: 600px) {
  .container0 {
    height: 80% !important;
  }
} */

.sticky-bottom {
  height: 10%;
}

.custom-toast {
  text-align: center;
  color: white; /* White text */
  font-weight: bold; /* Optional: makes text bold */
  border-radius: 5px; /* Optional: rounded corners */
}

.iconsstyleliked {
  opacity: 1 !important;
}
a {
  color: white !important;
}
.sharicons {
  width: 42px;
  border-radius: 50%;
}
body {
  background-color: #1a003b;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 400;
}
body p,
body li,
body label,
body span {
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 16px;
}
.flexbg {
  color: white;
  display: flex;
  justify-content: space-between;
  width: 98%;
  align-items: center;
}
.flexicons {
  display: flex;
  align-items: baseline;
}
.flexbgimg {
  width: 139px;
  height: 139px;
}
.mailimghidden {
  width: 28px;
  height: 30px;
  margin-right: 40px;
  opacity: 0.3;
}
.mailimg {
  width: 28px;
  height: 30px;
  margin-right: 40px;
  cursor: pointer;
  opacity: 0.5;
}
.plusimghidden {
  width: 26px;
  height: 26px;
  margin-right: 60px;
  margin-top: 3px;
  opacity: 0.3;
}
.plusimg {
  width: 26px;
  height: 26px;
  margin-right: 60px;
  margin-top: 3px;
  cursor: pointer;
  opacity: 0.5;
}
.flextitle {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 15px;
}
.titlebg2 {
  max-width: 1068px;
  height: 25px;
  opacity: 0.5;
  color: white;
  font-size: 20px;
}
.userbutton {
  background-color: #421d72 !important;
  cursor: auto !important;
  padding: 16px;
}
.botbutton {
  background-color: #450058 !important;
  cursor: auto !important;
  padding: 16px;
}

.botbuttonmarkdown {
  background-color: #450058 !important;
  cursor: auto !important;
  padding-left: 16px;
  padding-right: 16px;
}
.suggesstionbutton {
  top: 853px;
  left: 1030px;
  width: auto;
  max-width: 60%;
  height: auto;
  background-color: rgba(67, 30, 115, 1);
  border-radius: 8px;
  display: flex;
  align-items: stretch;
  margin-top: 2%;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  cursor: pointer;
}

.chamber {
  width: 580px;
  opacity: 0.75;
  border-radius: 8px;
}
.suggesstionbuttonsug {
  top: 853px;
  left: 1030px;
  width: 580px;
  height: auto;
  background-color: #332150;
  border-radius: 8px;
  display: flex;
  align-items: stretch;
  margin-top: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}
.suggesstionbuttontxt {
  top: 1012px;
  left: 1079px;
  /* width: 612px; */
  height: 100%;
  color: white;
  opacity: 0.8;
  display: flex;
  align-items: center;
  /* margin-left: 15px; */
  font-size: 16px;
  flex-wrap: wrap;
}
.suggesstionbuttontxtstyle {
  font-size: 14px !important;
}
.suggestionstyle {
  padding: 10px !important;
}
.suggesstionbuttontxtsug {
  top: 1012px;
  left: 1079px;
  /* width: 612px; */
  height: 100%;
  color: white;
  opacity: 0.8;
  display: flex;
  align-items: center;
  margin-left: 15px;
  font-size: 24px;
  font-weight: 700;
}

.flexsuggesstions {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  /* bottom: 47px; */
  left: 0;
  right: 0;
  width: 96%;
  margin: 0 auto;
  /* margin-top: 55px; */
}
.sendbox {
  top: 1450px;
  left: 701px;
  width: 60%;
  height: 50px;
  background-color: rgba(67, 30, 115, 1);
  border: 2.5px solid rgba(139, 119, 167, 1);
  border-radius: 11px;
  opacity: 1;
  margin: 0 auto;
  /* margin-top: 15px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sendinput {
  height: 40px;
  background-color: transparent;
  border: none;
  width: 91%;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 26px;
  color: white;
}
.sendimgActive {
  width: 37px;
  height: 37px;
  margin-right: 7px;
  cursor: pointer;
}
.sendimg {
  width: 37px;
  height: 37px;
  margin-right: 7px;
}

.sendinput:focus {
  box-shadow: none; /* Remove focus shadow if needed */
  border: none;
  outline: none;
}
.sendinput::placeholder {
  height: 40px;
  background-color: transparent;
  border: none;
  width: 91%;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 26px;
  color: white;
  opacity: 0.3;
}
.infosmall {
  color: white;
  font-size: 11.5px;
  opacity: 0.6;
  margin-top: 13px;
  text-align: center;
  padding-bottom: 10px;
}
.chatboxbg {
  /* height: 56vh; */
  overflow: scroll;
  width: 60%;
  margin: 0 auto;
  scrollbar-width: none; /* For Firefox */
}

.chatboxbg::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}
.flexuser {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.flexbot {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
  margin-bottom: 5px;
}
.suggestionstyle {
  margin-left: 2%;
  background-color: #332150 !important;
  cursor: pointer !important;
}
.sticky-bottom {
  position: fixed;
  background-color: #1a003b;
  bottom: 0;
  left: 0;
  right: 0;
}
.showmobtitle {
  display: none;
}
.hidemobtitle {
  display: flex;
}
.flexsuggestionbuttons {
  flex-wrap: wrap;
}
.flexsuggesstionsnew {
  display: none;
}
@media (min-width: 601px) and (max-width: 1550px) {
  .suggesstionbuttontxt {
    line-height: 25px !important;
  }
  .suggesstionbuttonsug {
    width: 375px;
  }
  .chamber {
    width: 375px;
  }
  .flexbgimg {
    width: 91px;
    height: 91px;
  }

  .mailimghidden {
    width: 21px;
    height: 23px;
    margin-right: 30px;
  }
  .plusimghidden {
    width: 20px;
    height: 20px;
    margin-right: 40px;
  }
  .mailimg {
    width: 21px;
    height: 23px;
    margin-right: 30px;
  }
  .plusimg {
    width: 20px;
    height: 20px;
    margin-right: 40px;
  }

  .mailimg:hover {
    opacity: 1 !important;
  }
  .plusimg:hover {
    opacity: 1 !important;
  }

  .titlebg2 {
    max-width: 100%;
    height: 25px;
    opacity: 0.5;
    color: white;
    font-size: 13.5px;
  }

  .suggesstionbuttontxtsug {
    font-size: 15px;
  }
  .sendinput {
    font-size: 17px;
    margin-left: 20px;
  }
  .sendinput::placeholder {
    font-size: 17px;
  }
}
/* .flexbot:hover .flexixons {
  display: flex;
} */
.flexixons {
  display: flex;
}
@media (max-width: 400px) {
  .chat-container {
    /* min-height: 66vh !important;  */
  }
}
@media (min-width: 401px) and (max-width: 500px) {
  .chat-container {
    /* min-height: 70vh !important; */
  }
}
@media (min-width: 600px) {
  .chat-container {
    /* height: 78vh; */
  }
}

@media (max-width: 600px) {
  .flexallmob {
    display: flex;
  }

  .suggesstionbutton {
    max-width: 80%;
  }
  .iconsstyle {
    width: 16px !important;
    height: 16px !important;
  }

  .mailimghidden {
    width: 18px;
    height: 20px;
    margin-right: 22px;
  }
  .plusimghidden {
    width: 17px;
    height: 17px;
    margin-right: 22px;
  }
  .mailimg {
    width: 18px;
    height: 20px;
    margin-right: 35px;
  }
  .plusimg {
    width: 17px;
    height: 17px;
    margin-right: 22px;
  }
  .showmobtitle {
    display: flex;
  }
  .hidemobtitle {
    display: none;
  }
  .flexsuggesstions {
    display: none;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    /* margin-bottom: 5px; */
    width: 97%;
  }
  .flexsuggesstionsnew {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5px;
    width: 97%;
    /* margin-top: 10%; */
  }
  .chamber {
    width: 97%;
    margin: 0px auto;
    /* padding-bottom: 20px; */
  }
  .suggesstionbuttonsug {
    top: 853px;
    left: 1030px;
    width: 49%;
    height: 83px;
    background-color: #332150;
    border-radius: 8px;
    display: flex;
    margin-top: 8px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    cursor: pointer;
    align-items: center;
  }

  .suggesstionbuttontxtsug {
    top: 1012px;
    left: 1030px;
    width: 100%;
    height: auto;
    /* background-color: rgba(67, 30, 115, 1); */
    border-radius: 8px;
    display: flex;
    margin-top: 1%;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    align-items: baseline;
    font-size: 16.5px;
    margin-right: 8px;
  }

  .chatboxbg {
    width: 94% !important;
    /* height: 49vh; */
  }

  .titlebg {
    max-width: 84%;
    height: auto;
    opacity: 0.5;
    color: white;
    font-size: 13.5px;
    text-align: center;
  }
  .flexbgimg {
    width: 85px;
    height: 85px;
  }
  .sendbox {
    width: 95%;
  }
  .sendinput {
    width: 83%;
    font-size: 17px;
  }
  .sendinput::placeholder {
    font-size: 16px;
  }
}

@media (min-width: 400px) and (max-width: 600px) {
  .chatboxbg {
    /* height: 56vh; */
  }
}
/* .reloadnew {
  width: 16px;
  height: 18px;
}
.sharenew {
  width: 18px;
  height: 18px;
} */
.flexixons {
  width: 30%;
  align-items: baseline;
  justify-content: flex-start;
  margin-left: 8px;
  margin-top: 10px;
  cursor: pointer;
}
.iconsstyle {
  width: 15px;
  height: 15px;
  margin-right: 20px;
  opacity: 0.3;
}
.iconsstyle:hover {
  opacity: 1;
}

.flexheader {
  display: flex;
  width: 100%;
}
.hiddendv {
  width: 33.33%;
}
.fleximage {
  display: flex;
  width: 33.33%;
  justify-content: center;
}

.flexiconsheader {
  display: flex;
  width: 33.33%;
  justify-content: flex-end;
  align-items: center;
}
.AdaptiveCardContentstyle p {
  color: blue; /* Change text color to blue */
  font-size: 16px; /* Set font size */
  margin: 10px 0; /* Set top and bottom margin */
}

.AdaptiveCardContentstyle {
  color: blue; /* Change text color to blue */
  font-size: 16px; /* Set font size */
  margin: 10px 0; /* Set top and bottom margin */
}
.chat-container {
  display: flex;
  flex-direction: column;
  /* height: 55vh; */
  /* height: auto; */
}

.chatboxbg {
  overflow-y: auto; /* Enable vertical scrolling */
  flex: 1; /* Allow it to grow and take remaining space */
  margin: 0 auto;
  scrollbar-width: none; /* For Firefox */
}

.chatboxbg::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}

.sticky-bottom {
  position: relative; /* Adjusted for flexbox context */
  background-color: #1a003b;
  /* padding-left: 10px;
  padding-right: 10px; */
}

@media (max-width: 600px) {
  .stickystatus {
    width: 95% !important;
  }
}
.stickystatus {
  background-color: transparent;
  width: 60%;
  margin: 0 auto;
  margin-top: 15px;
  color: white;
  opacity: 0.9;
  font-size: 12px;
  padding-left: 10px;
  padding-bottom: 5px;
}
.microphone-icon {
  width: 37px;
  height: 37px;
  margin-right: 7px;
  cursor: pointer;
  fill: #fff;
  opacity: 0.2;
}
.microphone-icon_dictating {
  width: 30px;
  height: 30px;
  margin-right: 7px;
  cursor: pointer;
  fill: #fff;
}

.Micloader {
  width: 25px;
  height: 25px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-right: 8px;
}

.listening-container {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}

.wave {
  display: flex;
  margin-left: 8px;
}

.wave span {
  animation: wave 1.2s infinite ease-in-out;
  font-size: 24px;
  margin: 0 2px;
}

.wave span:nth-child(1) {
  animation-delay: 0s;
}

.wave span:nth-child(2) {
  animation-delay: 0.2s;
}

.wave span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-8px);
  }
}
.spanlist {
  font-size: 12px !important;
}

.listening-container {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
}

.wave {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin-left: 10px;
}

.bar {
  width: 4px;
  height: 10px;
  background: white;
  margin: 0 2px;
  animation: waveAnimation 1s infinite ease-in-out;
}

.bar:nth-child(1) {
  animation-delay: 0s;
}
.bar:nth-child(2) {
  animation-delay: 0.1s;
}
.bar:nth-child(3) {
  animation-delay: 0.2s;
}
.bar:nth-child(4) {
  animation-delay: 0.3s;
}
.bar:nth-child(5) {
  animation-delay: 0.4s;
}

@keyframes waveAnimation {
  0%,
  100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(2);
  }
}

.transp {
  color: transparent;
}
