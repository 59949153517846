.nocur {
  cursor: auto !important;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.flexbuttons2x {
  width: 290px !important;
  margin: 30px auto !important;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.mar30 {
  margin-top: 30px !important;
}
.flexspinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}
.flexspinner2 {
  margin-top: 40px !important;
}
.spinnerimg {
  width: 80px;
}
/* start success */
/* 
.modal-emailsucc {
  width: 664px;
  height: 268px;
  background-color: #421d72;
  border-radius: 15px;
}
.startTitle2 {
  color: white;
  margin: 0 auto;
  font-size: 26px;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 700;
  text-align: center;
  margin-top: 34px;
  margin-bottom: 38px;
}

.startTitle3 {
  color: white;
  margin: 0 auto;
  font-size: 26px;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 700;
  text-align: center;
  margin-top: 34px;
  margin-bottom: 38px;
}
.desc3 {
  color: white;
  margin: 0 auto;
  font-size: 24px;
  font-family: "Helvetica Neue", sans-serif;
  width: 494px;
  height: 28px;
  text-align: center;
}

.desc4 {
  color: white;
  margin: 0 auto;
  font-size: 24px;
  font-family: "Helvetica Neue", sans-serif;
  width: 494px;
  height: 28px;
  text-align: center;
}

.flexbuttonsemail2 {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 52px;
}

.cancelbtn2 {
  width: 188px;
  height: 56px;
  border: 2px solid #ffffff;
  border-radius: 7px;
  display: flex;
  opacity: 0.8;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cancelbtn3 {
  width: 188px;
  height: 56px;
  border: 2px solid #ffffff;
  border-radius: 7px;
  display: flex;
  opacity: 0.8;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.modal-emailChat {
  width: 664px;
  height: 371px;
  background-color: #421d72;
  border-radius: 15px;
}

.desc2 {
  color: white;
  margin: 0 auto;
  font-size: 24px;
  font-family: "Helvetica Neue", sans-serif;
  width: 494px;
  height: 57px;
  text-align: center;
}
.invalidMessage {
  margin-top: 11px;
  width: 494px;
  color: red;
  margin: 0 auto;
  margin-top: 11px;
  margin-left: 120px;
}
.validmsg {
  visibility: hidden;
}

.inputMessage {
  width: 400px;
  height: 56px;
  border: 2px solid #8b77a7;
  border-radius: 7px;
  opacity: 1;
  margin: 6px auto;
  background-color: transparent;
  display: flex;
  font-size: 24px;
  font-family: "Helvetica Neue", sans-serif;
  color: white;
  padding-left: 20px;
}

.inputMessage::placeholder {
  width: 279px;
  height: 28px;
  opacity: 0.3;
  color: white;
  font-size: 24px;
  font-family: "Helvetica Neue", sans-serif;
  padding-left: 0px;
}

.flexbuttonsemail {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 28px;
}

.modal-contentChat {
  width: 664px;
  height: 321px;
  background-color: #421d72;
  border-radius: 15px;
}
.startTitle1 {
  color: white;
  margin: 0 auto;
  font-size: 26px;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 700;
  text-align: center;
  margin-top: 38px;
  margin-bottom: 38px;
}
.startTitle {
  color: white;
  margin: 0 auto;
  font-size: 26px;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 700;
  text-align: center;
  margin-top: 46px;
  margin-bottom: 40px;
}
.desc1 {
  color: white;
  margin: 0 auto;
  font-size: 24px;
  font-family: "Helvetica Neue", sans-serif;
  width: 517px;
  height: 57px;
}

.flexbuttons {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 62px;
}

.cancelbtn {
  width: 188px;
  height: 56px;
  border: 2px solid #ffffff;
  border-radius: 7px;
  display: flex;
  opacity: 0.8;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}

.cancelbtntxt {
  color: white;
  opacity: 1;
  font-size: 24px;
  font-family: "Helvetica Neue", sans-serif;
}

.yesbtn {
  width: 188px;
  height: 56px;
  border: 2px solid #ffffff;
  border-radius: 7px;
  display: flex;
  opacity: 1;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-left: 10px;
  cursor: pointer;
}

.yesbtntxt {
  color: #421d72;
  opacity: 1;
  font-size: 24px;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 700;
} */

.flexiconx {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.lablshare {
  color: white;
  font-size: 10px;
}
.shareflex {
  width: 100%;
  height: 65%;
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
  padding-top: 5px;
}
.shareTitle {
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.no-select {
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.flexheadshare {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin: 5px auto !important;
}

@media (min-width: 701px) {
  /* start success */

  .modal-emailsucc {
    width: 337px;
    height: 174px;
    background-color: #421d72;
    border-radius: 12px;
  }
  .startTitle2 {
    color: white;
    margin: 0 auto;
    font-size: 18px;
    font-family: "Helvetica Neue", sans-serif;
    font-weight: 700;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 24px;
  }
  .startTitle3 {
    color: white;
    margin: 0 auto;
    font-size: 18px;
    font-family: "Helvetica Neue", sans-serif;
    font-weight: 700;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .desc3 {
    color: white;
    width: 100%;
    margin: 0 auto;
    font-size: 16.5px;
    font-family: "Helvetica Neue", sans-serif;
    height: 20px;
    text-align: center;
  }

  .desc4 {
    color: white;
    width: 290px;
    margin: 0 auto;
    font-size: 16.5px;
    font-family: "Helvetica Neue", sans-serif;
    height: 20px;
    text-align: center;
  }

  .flexbuttonsemail2 {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 32px;
  }

  .cancelbtn2 {
    width: 155px;
    height: 41px;
    border: 2px solid #ffffff;
    border-radius: 7px;
    display: flex;
    opacity: 0.8;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16.5px;
  }

  .cancelbtn3 {
    width: 132px;
    height: 41px;
    border: 2px solid #ffffff;
    border-radius: 7px;
    display: flex;
    opacity: 0.8;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16.5px;
  }

  /* End of success  */

  .invalidMessage {
    margin-top: 6px !important;
    margin-bottom: 2px;
    width: 494px;
    color: red;
    margin: 0 auto;
    margin-left: 108px;
    font-size: 12px;
    text-align: left;
  }
  .validmsg {
    visibility: hidden;
  }

  .inputMessage {
    width: 279px;
    height: 41px;
    border: 2px solid #8b77a7;
    border-radius: 7px;
    opacity: 1;
    margin: 2px auto;
    background-color: transparent;
    display: flex;
    font-size: 16.5px;
    font-family: "Helvetica Neue", sans-serif;
    color: white;
    padding-left: 20px;
    background-color: #2d1e45;
  }

  .inputMessage::placeholder {
    width: 279px;
    height: 28px;
    opacity: 0.3;
    color: white;
    font-size: 16.5px;
    font-family: "Helvetica Neue", sans-serif;
    padding-left: 0px;
  }

  .flexbuttonsemail {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 17px;
  }

  .modal-emailChat {
    width: 519px;
    height: 255px;
    background-color: #421d72;
    border-radius: 12px;
  }

  .startTitle1 {
    color: white;
    margin: 0 auto;
    font-size: 18px;
    font-family: "Helvetica Neue", sans-serif;
    font-weight: 700;
    text-align: center;
    margin-top: 18px;
    margin-bottom: 17px;
  }

  .desc2 {
    color: white;
    margin: 0 auto;
    font-size: 16.5px;
    font-family: "Helvetica Neue", sans-serif;
    width: 290px;
    height: 40px;
    text-align: center;
  }

  .modal-contentChat {
    width: 400px;
    height: 218px;
    background-color: #421d72;
    border-radius: 12px;
  }
  .startTitle {
    color: white;
    margin: 0 auto;
    font-size: 18px;
    font-family: "Helvetica Neue", sans-serif;
    font-weight: 700;
    text-align: center;
    margin-top: 18px;
    margin-bottom: 19px;
  }
  .desc1 {
    color: white;
    margin: 0 auto;
    font-size: 16.5px;
    font-family: "Helvetica Neue", sans-serif;
    width: 290px;
    height: 60px;
    text-align: center;
  }

  .flexbuttons {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 30px;
  }

  .cancelbtn {
    width: 132px;
    height: 41px;
    border: 2px solid #ffffff;
    border-radius: 7px;
    display: flex;
    opacity: 0.8;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
    cursor: pointer;
  }

  .cancelbtntxt {
    color: white;
    opacity: 1;
    font-size: 16.5px;
    font-family: "Helvetica Neue", sans-serif;
  }

  .yesbtn {
    width: 132px;
    height: 41px;
    border: 2px solid #ffffff;
    border-radius: 7px;
    display: flex;
    opacity: 1;
    align-items: center;
    justify-content: center;
    background-color: white;
    margin-left: 6px;
    cursor: pointer;
  }

  .yesbtntxt {
    color: #421d72;
    opacity: 1;
    font-size: 16.5px;
    font-family: "Helvetica Neue", sans-serif;
    font-weight: 700;
  }
}
@media (max-width: 700px) {
  .loader {
    width: 38px;
    height: 38px;
  }
  .spinnerimg {
    width: 50px;
    margin-top: 15px;
  }
  /* start success */

  .modal-emailsucc {
    width: 337px;
    height: 174px;
    background-color: #421d72;
    border-radius: 15px;
  }
  .startTitle2 {
    color: white;
    margin: 0 auto;
    font-size: 18px;
    font-family: "Helvetica Neue", sans-serif;
    font-weight: 700;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 24px;
  }
  .startTitle3 {
    color: white;
    margin: 0 auto;
    font-size: 18px;
    font-family: "Helvetica Neue", sans-serif;
    font-weight: 700;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .desc3 {
    color: white;
    width: 100%;
    margin: 0 auto;
    font-size: 16.5px;
    font-family: "Helvetica Neue", sans-serif;
    height: 20px;
    text-align: center;
  }

  .desc4 {
    color: white;
    width: 290px;
    margin: 0 auto;
    font-size: 16.5px;
    font-family: "Helvetica Neue", sans-serif;
    height: 20px;
    text-align: center;
  }

  .flexbuttonsemail2 {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 32px;
  }

  .cancelbtn2 {
    width: 155px;
    height: 41px;
    border: 2px solid #ffffff;
    border-radius: 7px;
    display: flex;
    opacity: 0.8;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16.5px;
  }

  .cancelbtn3 {
    width: 132px;
    height: 41px;
    border: 2px solid #ffffff;
    border-radius: 7px;
    display: flex;
    opacity: 0.8;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16.5px;
  }

  /* End of success  */

  .invalidMessage {
    margin-top: 6px !important;
    margin-bottom: 2px;
    width: 494px;
    color: red;
    margin: 0 auto;
    margin-left: 20px;
    font-size: 12px;
  }
  .validmsg {
    visibility: hidden;
  }

  .inputMessage {
    width: 279px;
    height: 41px;
    border: 2px solid #8b77a7;
    border-radius: 7px;
    opacity: 1;
    margin: 2px auto;
    background-color: transparent;
    display: flex;
    font-size: 16.5px;
    font-family: "Helvetica Neue", sans-serif;
    color: white;
    padding-left: 20px;
    background-color: #2d1e45;
  }

  .inputMessage::placeholder {
    width: 279px;
    height: 28px;
    opacity: 0.3;
    color: white;
    font-size: 16.5px;
    font-family: "Helvetica Neue", sans-serif;
    padding-left: 0px;
  }

  .flexbuttonsemail {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 17px;
  }

  .modal-emailChat {
    width: 337px;
    height: 255px;
    background-color: #421d72;
    border-radius: 15px;
  }

  .startTitle1 {
    color: white;
    margin: 0 auto;
    font-size: 18px;
    font-family: "Helvetica Neue", sans-serif;
    font-weight: 700;
    text-align: center;
    margin-top: 18px;
    margin-bottom: 17px;
  }

  .desc2 {
    color: white;
    margin: 0 auto;
    font-size: 16.5px;
    font-family: "Helvetica Neue", sans-serif;
    width: 290px;
    height: 40px;
    text-align: center;
  }

  .modal-contentChat {
    width: 337px;
    height: 209px;
    background-color: #421d72;
    border-radius: 12px;
  }
  .startTitle {
    color: white;
    margin: 0 auto;
    font-size: 18px;
    font-family: "Helvetica Neue", sans-serif;
    font-weight: 700;
    text-align: center;
    margin-top: 18px;
    margin-bottom: 19px;
  }
  .desc1 {
    color: white;
    margin: 0 auto;
    font-size: 16.5px;
    font-family: "Helvetica Neue", sans-serif;
    width: 290px;
    height: 60px;
    text-align: center;
  }

  .flexbuttons {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 30px;
  }

  .cancelbtn {
    width: 132px;
    height: 41px;
    border: 2px solid #ffffff;
    border-radius: 7px;
    display: flex;
    opacity: 0.8;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
    cursor: pointer;
  }

  .cancelbtntxt {
    color: white;
    opacity: 1;
    font-size: 16.5px;
    font-family: "Helvetica Neue", sans-serif;
  }

  .yesbtn {
    width: 132px;
    height: 41px;
    border: 2px solid #ffffff;
    border-radius: 7px;
    display: flex;
    opacity: 1;
    align-items: center;
    justify-content: center;
    background-color: white;
    margin-left: 6px;
    cursor: pointer;
  }

  .yesbtntxt {
    color: #421d72;
    opacity: 1;
    font-size: 16.5px;
    font-family: "Helvetica Neue", sans-serif;
    font-weight: 700;
  }
}

/* @media (max-width: 800px) {
  .modal-content {
    width: 60% !important;
  }
} */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #421d72;
  padding: 0px 5px 10px 5px;
  border-radius: 8px;
  position: relative;
  width: 330px;
  height: 120px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
}

.quickbtn {
  background-color: transparent;
  color: #fff;
  font-size: 12px;
  width: 200px;
  height: 33px;
  border: solid 1px;
  border-color: #fff;
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
  cursor: pointer;
  padding-left: 5px;
}
.testhead {
  color: white;
}

.quickbtn2 {
  background-color: transparent;
  color: #fff;
  font-size: 12px;
  width: 140px;
  height: 33px;
  border: solid 1px;
  border-color: #fff;
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
  cursor: pointer;
  padding-left: 5px;
}

.input2 {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}
.flex3 {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
